import React from 'react';
import Navbar from './Navbar';
import Router from './Router';

import './App.css';

function App() {

  return (
    <div className="App">
      <Navbar />
      <main>
        <Router />
      </main>
    </div>
  );
}

export default App;
