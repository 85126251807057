import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

import './index.css';

const DASHBOARD_WEB_LINK = 'https://dashboard.civapi.com';

const Navbar: React.FC = () => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    window.location.href = DASHBOARD_WEB_LINK;
  }, []);

  return (
    <nav className='app-navbar'>
      <div className='flex-wrapper space-between'>
        <strong style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => navigate('/')}>CivAPI</strong>
        <Button color='light' onClick={onClick}>
          Get Started
        </Button>
      </div>
    </nav>
  );
};

export default Navbar;
