import React from 'react';
import HeroSection from '../HeroSection';
// import ContactUs from '../ContactUs';

const Home: React.FC = () => {
  return (
    <>
      <HeroSection />
      {/* <ContactUs /> */}
    </>
  );
};

export default Home;
